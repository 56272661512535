/* Built In Imports */
import { useContext } from 'react';

/* External Imports */
import { Box } from '@chakra-ui/react';

/* Internal Imports */
/* Components */
import LazyLoadImageComponent from '@components/UI/LazyLoadImageComponent';
import config from '@config';
import { AuthContext } from '@store/auth-context';

/* Services */

/**
 * Renders Uk Cookie
 * @returns {ReactElement} -  Uk Cookie
 */
const UkCookie = () => {
  const authContext = useContext(AuthContext);
  const isUKCookieSet = authContext.uk_cookie;

  return (
    // position: fixed; z-index: 9999; left: 0; bottom: 0; width: 100%; height: auto; overflow: auto; background-color: #353535;
    <Box
      pos="fixed"
      zIndex="9999"
      left="0"
      bottom="0"
      w="100%"
      h="auto"
      overflow="auto"
      bgColor="#353535"
    >
      {isUKCookieSet !== 'yes' && authContext?.region === 'uk' && (
        <Box
          width="100%"
          float="left"
          backgroundColor="#353535"
          p={'0 35px 15px'}
          position="relative"
        >
          <Box
            pos="absolute"
            top="16px"
            right="25px"
            cursor="pointer"
            onClick={authContext?.RegionUKCookies}
          >
            <LazyLoadImageComponent
              alt="close"
              height="20px"
              width="20px"
              src={`${config.staticPath}/assets/images/close.svg`}
              float="left"
            />
          </Box>
          <Box float="left" w="100%" mt="10px">
            <Box
              float="left"
              w="100%"
              fontSize="16px"
              fontFamily="FedraSansStd-book"
              fontWeight="500"
              color="#ffffff"
              lineHeight="29px"
            >
              We use cookies to ensure you get the best experience on our
              website, including personalisation of ads. By using
              Isha.Sadhguru.org, you accept our use of cookies.
            </Box>
          </Box>
        </Box>
      )}
    </Box>
  );
};
export default UkCookie;
